import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { FontAwesomeIcon as F } from '@fortawesome/react-fontawesome';
import { faCaretLeft as left, faCaretRight as right } from '@fortawesome/free-solid-svg-icons';
import c from 'classnames';
import { provideData } from '../data';
import MDXPage, { Consumer } from '../components/MDXPage';
import PageMeta from '../components/PageMeta';
import Section from '../components/Section';
import FloatingNavbar from '../components/FloatingNavbar';
import MapboxMap from '../components/MapboxMap';
import Link from '../components/Link';
import Header from '../components/Header.mdx';
import StaticNavbar from '../components/StaticNavbar.mdx';
import { Percent, CurrencyWithCents, Decimal, Multiple, DateString } from '../components/Formatted';
import styles from './index.module.css';
export const getSingleId = (slug, {
  parcelsBySlug
}) => parcelsBySlug[slug];
export const getSingleData = (parcelId, {
  parcelPrevNextById,
  parcelCountyById,
  parcelStateById,
  parcelMtrsaIdById,
  parcelTransferById,
  transferAllFieldsById,
  stateAllFieldsById,
  parcelNotesHTMLById,
  parcelAreaById,
  paidToTribesByParcel,
  tribesByParcel,
  tribeAllFieldsById,
  parcelGrantStateById,
  universitiesByState,
  universityAllFieldsById,
  parcelPatentedYearById,
  raisedByUniversitiesByParcel,
  parcelSourceHTMLById
}) => ({
  parcelId,
  ...parcelPrevNextById[parcelId],
  county: parcelCountyById[parcelId],
  stateAbbreviation: stateAllFieldsById[parcelStateById[parcelId]].abbreviation,
  mtrsaId: parcelMtrsaIdById[parcelId],
  transferType: transferAllFieldsById[parcelTransferById[parcelId]].type,
  transferDate: transferAllFieldsById[parcelTransferById[parcelId]].transferDate,
  notesHTML: parcelNotesHTMLById[parcelId],
  area: parcelAreaById[parcelId],
  transferYear: transferAllFieldsById[parcelTransferById[parcelId]].transferYear,
  paidToTribes: paidToTribesByParcel[parcelId],
  tribes: tribesByParcel[parcelId].map(tribe => tribeAllFieldsById[tribe]).map(({
    name,
    slug
  }) => ({
    name,
    slug
  })),
  grantStateName: stateAllFieldsById[parcelGrantStateById[parcelId]].name,
  grantStateGrantReceivedYear: stateAllFieldsById[parcelGrantStateById[parcelId]].grantReceivedYear,
  universities: universitiesByState[parcelGrantStateById[parcelId]].map(university => universityAllFieldsById[university]).map(({
    slug,
    grantReceivedYear,
    name,
    grantShare
  }) => ({
    slug,
    grantReceivedYear,
    name,
    grantShare
  })),
  patentedYear: parcelPatentedYearById[parcelId],
  raisedByUniversities: raisedByUniversitiesByParcel[parcelId],
  sourceHTML: parcelSourceHTMLById[parcelId]
});
export const getParcelStats = ({
  area,
  transferYear,
  paidToTribes
}) => [[<Decimal mdxType="Decimal">{area}</Decimal>, 'Size in acres'], [transferYear, 'Acquired by U.S.'], [<CurrencyWithCents mdxType="CurrencyWithCents">{paidToTribes}</CurrencyWithCents>, 'U.S. Paid']];
export const getParcelTransferStats = ({
  grantStateName,
  grantStateGrantReceivedYear
}) => [[grantStateName, 'State receiving land grant'], [grantStateGrantReceivedYear, 'Granted to state']];
export const getParcelSaleStats = ({
  universities,
  patentedYear,
  raisedByUniversities,
  paidToTribes
}) => [[[...new Set(universities.map(u => u.grantReceivedYear))].join(', '), `Universit${universities.length === 1 ? 'y' : 'ies'} accepted`], [patentedYear || '–', 'Date land patented'], [<CurrencyWithCents mdxType="CurrencyWithCents">{raisedByUniversities}</CurrencyWithCents>, `Universit${universities.length === 1 ? 'y' : 'ies'} raised`], [<Multiple suffix="x" mdxType="Multiple">{raisedByUniversities / paidToTribes}</Multiple>, 'Return on treaty payments to tribes', styles.textFigureWithBorder]];
export const remainMountedChildren = <>
    <Header mdxType="Header" />
    <StaticNavbar mdxType="StaticNavbar" />
    <FloatingNavbar anchorLinks={[{
    href: '#map',
    label: 'Map'
  }, {
    href: '#list-of-all-lands',
    label: 'List of All Lands'
  }]} mdxType="FloatingNavbar" />
    <Section containerWidth className="mb-3" colProps={{
    className: styles.titleBar
  }} mdxType="Section">
      <Consumer render={({
      prevSlug
    }) => <Link className={c('dd-none d-desktop-flex', styles.prevLink)} href={`/lands/${prevSlug}`} mdxType="Link">
            <F icon={left} mdxType="F" />
            Previous
          </Link>} mdxType="Consumer" />
      <Consumer render={({
      county,
      stateAbbreviation
    }) => <div className={styles.title}>
            <h2 className={styles.landHeader}>
              {`Land Parcel in ${county} County, ${stateAbbreviation}`}
            </h2>
          </div>} mdxType="Consumer" />
      <Consumer render={({
      nextSlug
    }) => <Link className={c('dd-none d-desktop-flex', styles.nextLink)} href={`/lands/${nextSlug}`} mdxType="Link">
            Next
            <F icon={right} mdxType="F" />
          </Link>} mdxType="Consumer" />
    </Section>
    <a name="map" />
    <MapboxMap key="map" mapTitle={<h4 className={styles.landMap}>
          <Consumer render={({
      mtrsaId
    }) => <div>{`Parcel ${mtrsaId}`}</div>} mdxType="Consumer" />
          <Consumer render={({
      transferType,
      transferDate
    }) => <div>
                {transferType}
                <DateString prefix=", " invalidDate="" mdxType="DateString">
                  {transferDate}
                </DateString>
              </div>} mdxType="Consumer" />
        </h4>} mapSubtitle="Morrill Act parcels" mdxType="MapboxMap" />
  </>;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  getSingleId,
  getSingleData,
  getParcelStats,
  getParcelTransferStats,
  getParcelSaleStats,
  remainMountedChildren,
  _frontmatter
};
const MDXLayout = provideData(MDXPage, ['parcelsBySlug', 'parcelPrevNextById', 'parcelCountyById', 'parcelStateById', 'parcelMtrsaIdById', 'parcelTransferById', 'transferAllFieldsById', 'stateAllFieldsById', 'parcelNotesHTMLById', 'parcelAreaById', 'paidToTribesByParcel', 'tribesByParcel', 'tribeAllFieldsById', 'parcelGrantStateById', 'universitiesByState', 'universityAllFieldsById', 'parcelPatentedYearById', 'raisedByUniversitiesByParcel', 'parcelSourceHTMLById']);
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">









    <Consumer render={({
      county,
      stateAbbreviation
    }) => <PageMeta title={`Land Parcel in ${county} County, ${stateAbbreviation}`} mdxType="PageMeta" />} mdxType="Consumer" />
    <Section paragraphWidth className="mt-5 mb-4" mdxType="Section">
  <Consumer render={({
        notesHTML
      }) => <p dangerouslySetInnerHTML={{
        __html: notesHTML
      }} />} mdxType="Consumer" />
    </Section>
    <Section figureWidth className="dd-none d-desktop-block mb-4" mdxType="Section">
  <p className={styles.subheading}>Land Parcel Info</p>
  <div className="row justify-content-center">
    <Consumer render={d => getParcelStats(d).map(([figure, caption, figureStyles]) => <div key={caption} className="col-2">
            <figure className={c(styles.textFigure, figureStyles)}>
              {figure}
              <figcaption>{caption}</figcaption>
            </figure>
          </div>)} mdxType="Consumer" />
    <div className="col-6">
      <div className={styles.miniHeader}>Treaty Co-signers</div>
      <p className={styles.bigParagraph}>
        <Consumer render={({
              tribes
            }) => tribes.map(({
              slug,
              name
            }, i) => <span key={slug}>
                <Link href={`/tribes/${slug}`} mdxType="Link">{name}</Link>
                {i === tribes.length - 1 ? null : '; '}
              </span>)} mdxType="Consumer" />
      </p>
    </div>
  </div>
    </Section>
    <Section figureWidth className="dd-none d-desktop-block mb-4" mdxType="Section">
  <p className={styles.subheading}>Transfer Under Morrill Act</p>
  <div className="row justify-content-center">
    <Consumer render={d => getParcelTransferStats(d).map(([figure, caption, figureStyles]) => <div key={caption} className="col-2">
            <figure className={c(styles.textFigure, figureStyles)}>
              {figure}
              <figcaption>{caption}</figcaption>
            </figure>
          </div>)} mdxType="Consumer" />
    <div className="col-8">
      <div className={styles.miniHeader}>Land Grant Universit(ies) / Share</div>
      <Consumer render={({
            universities
          }) => universities.map(({
            slug,
            name,
            grantShare
          }) => <p key={name} className={styles.bigParagraph}>
              <Link href={`/universities/${slug}`} mdxType="Link">
                {name} [<Percent mdxType="Percent">{grantShare}</Percent>]
              </Link>
            </p>)} mdxType="Consumer" />
    </div>
  </div>
    </Section>
    <Section figureWidth className="dd-none d-desktop-block mb-4" mdxType="Section">
  <p className={styles.subheading}>University Gains</p>
  <div className="row justify-content-center">
    <Consumer render={d => getParcelSaleStats(d).map(([figure, caption, figureStyles]) => <div key={caption} className="col-2">
            <figure className={c(styles.textFigure, figureStyles)}>
              {figure}
              <figcaption>{caption}</figcaption>
            </figure>
          </div>)} mdxType="Consumer" />
  </div>
    </Section>
    <Section paragraphWidth className="mb-4 text-center" mdxType="Section">
  <div className={styles.miniHeader}>Source</div>
  <Consumer render={({
        sourceHTML
      }) => <em>
        <p dangerouslySetInnerHTML={{
          __html: sourceHTML
        }} />
      </em>} mdxType="Consumer" />
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      